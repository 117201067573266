<script lang="ts" setup>
import type { HeroBanner } from '@integration-layer/ampliance/schemas/hero-banner-schema.localized'
import type { EditorialContentProps } from '@design-system/components/Editorial/EditorialContent.props'

const props = defineProps<{
  deliveryId: string
  locale: string
  vse?: string
  content?: HeroBanner
}>()

const data = await useComponentResponse<HeroBanner>(props)
</script>

<template>
  <EditorialContent
    v-if="data"
    v-bind="data as unknown as EditorialContentProps"
  />
</template>
